import { createSignal, useContext, Show, createEffect } from 'solid-js';
import { Title } from '@solidjs/meta';
import { Placements } from '@24hr/gullvikspraktikan-entities';
import { useSearchParams } from '@solidjs/router';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Screen } from '../../components/layout/screen/screen';
import { BreadCrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { Header } from '../../components/layout/header/header';
import { Container } from '../../components/layout/container/container';
import { RegCheckSearchField } from '../../components/regcheck-search-field/regcheck-search-field';
import { RegCheckSearchResults } from '../../components/regcheck-search-results/regheck-search-results';
import { regNoSearchQuery } from '../../graphql/queries/registration-number-check';
import { RegCheckSearchPrompt } from '../../components/regcheck-search-prompt/regcheck-search-prompt';
import { getProductsWithRegistrationNumberQuery } from '../../graphql/queries/products';
import { AdsContainer } from '../ads/ads-container';
import { StyledVerticalSpace } from '../../components/layout/layout.styles';
import { SkeletonLoader } from '../../components/skeleton-loader/skeleton-loader';
import { RegistrationNumberCheckItemType } from '../../components/regcheck-search-results/regcheck-search-results-item/regcheck-search-results-item';

export type MatchingProduct = { registrationNumber: string; slug: string };

export const RegCheckContainer = () => {
    const { graphQlClient } = useContext(AppContext);
    const [isLoading, setIsLoading] = createSignal(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = createSignal(searchParams.s || '');
    const [matchedProducts, setMatchedProducts] = createSignal<MatchingProduct[]>([]);
    const [isFetchingMatchedProducts, setIsFetchingMatchedProducts] = createSignal(false);
    const [registrationNumbers, setRegistrationNumbers] = createSignal<RegistrationNumberCheckItemType[]>([]);

    createEffect(async () => {
        setSearchParams({ s: searchTerm() });

        if (!searchTerm() || searchTerm().length < 3) {
            return [];
        }

        setIsLoading(true);
        const response = await graphQlClient(regNoSearchQuery, { searchTerm: searchTerm() });
        setIsLoading(false);

        const data = response?.regNoSearch || [];

        if (JSON.stringify(data) !== JSON.stringify(registrationNumbers)) {
            setRegistrationNumbers(data);
            fetchMathingProducts(data);
        }
    });

    const fetchMathingProducts = async (registrationNumbers: RegistrationNumberCheckItemType[]) => {
        if (isFetchingMatchedProducts()) {
            return;
        }

        setIsFetchingMatchedProducts(true);

        const regNoSet = new Set(registrationNumbers.map(({ regNumber }) => regNumber));
        const response = await graphQlClient(getProductsWithRegistrationNumberQuery, {
            registrationNumbers: [...regNoSet],
        });

        setIsFetchingMatchedProducts(false);

        setMatchedProducts((response?.products as MatchingProduct[]) || []);
    };

    return (
        <Screen>
            <Title>Regkoll</Title>
            <BreadCrumbs prompt="Regkoll" />
            <Header header="Regkoll" backLink="/" />
            <Container>
                <RegCheckSearchField searchQuery={searchTerm} sendSearchQuery={setSearchTerm} />
                <Show
                    when={!isLoading()}
                    fallback={
                        <div style={{ 'margin-top': '2.5rem' }}>
                            <SkeletonLoader marginBottom="1rem" height={77} />
                        </div>
                    }
                >
                    <Show
                        when={searchTerm().length < 3}
                        fallback={
                            <RegCheckSearchResults
                                searchTerm={searchTerm()}
                                registrationNumbers={registrationNumbers()}
                                matchedProducts={matchedProducts()}
                            />
                        }
                    >
                        <RegCheckSearchPrompt />
                    </Show>
                </Show>
                <StyledVerticalSpace />
                <AdsContainer placement={Placements.RegistrationNumberCheck} />
            </Container>
        </Screen>
    );
};
