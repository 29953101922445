import { styled } from 'solid-styled-components';

export const StyledDatePickerInput = styled.input`
    width: 100%;
    padding: 9px 12px;
    box-sizing: border-box;
    cursor: text;
    position: relative;
    border: 1px solid #a4343a;
    border-radius: 8px;

    &:focus,
    &:active {
        outline: none;
        border-color: #a4343a;
    }
`;

export const StyledCreateDaytimeDegreesForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    button {
        border-radius: 8px;
    }
`;

export const StyledMap = styled.div`
    height: 400px;
    width: 100%;
`;

export const StyledMapLabel = styled.p`
    display: block;
    font-weight: bold;
    margin-bottom: 0;
`;

export const StyledDatepicker = styled.div`
    margin-top: -5px;

    input {
        display: none;
    }

    .air-datepicker-body--day-name {
        color: #a4343a;
        text-transform: lowercase;
    }

    .air-datepicker-cell.-current- {
        color: #a4343a;
        font-weight: bold;
    }

    .air-datepicker-cell.-selected-,
    .air-datepicker-cell.-selected-.-focus- {
        background: #a4343a;
    }
`;
