import { For, Show } from 'solid-js';
import { Image } from '../image/image';
import type { ContactProps } from './contacts.d';
import {
    StyledContactItem,
    StyledContactName,
    StyledContactEmailLink,
    StyledContactTel,
    StyledContactEmails,
    StyledJobTitle,
} from './contacts.styles';

export const Contact = (props: ContactProps) => {
    const getEmails = () => {
        if (!props.contact.email) {
            return [];
        }

        return props.contact.email
            .split(',')
            .filter((email) => email)
            .map((email) => email.trim());
    };

    const getTels = () => {
        if (!props.contact.tel) {
            return [];
        }

        return props.contact.tel
            .split(',')
            .filter((tel) => tel)
            .map((tel) => tel.trim());
    };

    return (
        <StyledContactItem>
            <Show when={props.contact.imageSrc}>
                <Image src={props.contact.imageSrc} alt={props.contact.name} crop="150x200" />
            </Show>
            <StyledContactName hasJobTitle={props.contact.jobTitle?.length}>{props.contact.name}</StyledContactName>
            <Show when={props.contact.jobTitle}>
                <StyledJobTitle>{props.contact.jobTitle}</StyledJobTitle>
            </Show>
            <Show when={getTels().length}>
                <StyledContactTel>
                    TEL
                    <For each={getTels()}>{(phone) => <a href={`tel:${phone}`}>{phone}</a>}</For>
                </StyledContactTel>
            </Show>
            <StyledContactEmails>
                <For each={getEmails()}>{(email) => <StyledContactEmailLink href={`mailto:${email}`}>{email}</StyledContactEmailLink>}</For>
            </StyledContactEmails>
        </StyledContactItem>
    );
};
