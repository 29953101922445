import { createSignal, For, Show } from 'solid-js';
import { Placements } from '@24hr/gullvikspraktikan-entities';
import { StyledVerticalSpace } from '../layout/layout.styles';
import { Select } from '../select/select';
import { Contact, ContactArea, ContactsProps } from './contacts.d';
import {
    StyledContacts,
    StyledContactHeadline,
    StyledContactsList,
    StyledSelectWrapper,
    StyledNoContactsContainer,
} from './contacts.styles';
import { SelectOption } from '../select/select.types';
import { Contact as ContactComponent } from './contact';
import { AdsContainer } from '../../containers/ads/ads-container';

export const Contacts = (props: ContactsProps) => {
    const contacts = (): Contact[] => props.contactsData()?.contacts?.rows || [];
    const contactAreas = (): ContactArea[] => props.contactAreasData()?.contactAreas?.rows || [];
    const [selectedContactArea, setSelectedContactArea] = createSignal<string>('');

    const contactAreaOptions = (): SelectOption[] => {
        const options: SelectOption[] = [
            {
                value: '',
                text: ' -- Välj plats --',
            },
        ];

        contactAreas().forEach(({ slug, name, children }) => {
            options.push({ value: slug, text: `<strong>${name}</strong>`, disabled: true });

            children?.forEach((child: ContactArea) => {
                options.push({ value: child.slug, text: child.name });
            });
        });

        return options;
    };

    const contactsInRegion = () => {
        if (selectedContactArea() === '') {
            return [];
        }

        return contacts()
            .filter(({ contactAreas }) => {
                return !!contactAreas.find(({ slug }) => slug === selectedContactArea());
            })
            .map((contact) => {
                const contactAreaForContact = contact.contactAreas.find(({ slug }) => slug === selectedContactArea());

                return {
                    ...contact,
                    priority: contactAreaForContact?.priority || 1,
                };
            })
            .sort((a, b) => a.priority - b.priority || a.name.localeCompare(b.name, 'sv'));
    };

    return (
        <StyledContacts>
            <Show when={contactAreas().length}>
                <StyledContactHeadline>Kontakta oss</StyledContactHeadline>
                <StyledSelectWrapper>
                    <Select mode="basic" onChange={setSelectedContactArea} options={contactAreaOptions()} value={selectedContactArea()} />
                </StyledSelectWrapper>
                <StyledVerticalSpace size={3} />
                <StyledContactsList>
                    <Show when={selectedContactArea() !== ''}>
                        <For
                            each={contactsInRegion()}
                            fallback={<StyledNoContactsContainer>Inga kontakter hittades i området</StyledNoContactsContainer>}
                        >
                            {(contact) => <ContactComponent contact={contact} />}
                        </For>
                    </Show>
                </StyledContactsList>
            </Show>

            <AdsContainer placement={Placements.Contacts} />
        </StyledContacts>
    );
};
