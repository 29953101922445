import { Title } from '@solidjs/meta';
import { useNavigate } from '@solidjs/router';
import { useContext } from 'solid-js';
import { createStore } from 'solid-js/store';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { BreadCrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { CreateDaytimeDegreesForm } from '../../components/daytime-degrees/create-daytime-degrees-form';
import { Container } from '../../components/layout/container/container';
import { Header } from '../../components/layout/header/header';
import { Screen } from '../../components/layout/screen/screen';
import { getDaytimeDegreesCropsQuery } from '../../graphql/queries/daytime-degrees';
import { LocalStorageDaytimeDegrees } from './daytime-degrees-types';
import { getFromLocalStorage, setLocalStorage } from './dd-localstorage';
import { format } from 'date-fns';

export const CreateDaytimeDegreesContainer = () => {
    const navigate = useNavigate();
    const { createCachedResource } = useContext(AppContext);

    const [formValues, setFormValues] = createStore<LocalStorageDaytimeDegrees>({
        cropSlug: '',
        date: format(new Date(), 'yyyy-MM-dd'),
        lat: 55.990257,
        lng: 13.595769,
        note: '',
        hiddenTreatments: [],
    });

    const links = () => [
        {
            text: 'Daggrader',
            url: `/daggrader/`,
        },
    ];

    const [daytimeDegreesCrops] = createCachedResource(getDaytimeDegreesCropsQuery, { limit: 1000 });

    const addDaytimeDegreeToLocalStorage = () => {
        try {
            const daytimeDegrees = getFromLocalStorage();
            daytimeDegrees.push(formValues);
            setLocalStorage(daytimeDegrees);
        } catch (err) {
            // console.log(err);
        }

        navigate('/daggrader');
    };

    const getCropsData = () => {
        const crops = new Set<{ value: string; text: string; dateTitle: string }>();

        const ddCrops = () =>
            daytimeDegreesCrops()?.daytimeDegreesCrops?.rows as {
                crop: {
                    slug: string;
                    name: string;
                    uuid: string;
                };
                dateTitle: string;
            }[];

        ddCrops()?.forEach(({ crop, dateTitle }) => {
            crops.add({
                value: crop.slug,
                text: crop.name,
                dateTitle: dateTitle,
            });
        });

        return [...crops];
    };

    return (
        <Screen>
            <Title>Ny gradkontroll - Gullvikspraktikan</Title>
            <BreadCrumbs links={links()} prompt="Lägg till daggrader..." />
            <Header header="Ny gradkoll" backLink="/daggrader" />
            <Container>
                <CreateDaytimeDegreesForm
                    crops={getCropsData()}
                    formValues={formValues}
                    updateForm={setFormValues}
                    addDaytimeDegree={addDaytimeDegreeToLocalStorage}
                />
            </Container>
        </Screen>
    );
};
