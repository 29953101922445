import { Title } from '@solidjs/meta';
import { Placements } from '@24hr/gullvikspraktikan-entities';
import { Screen } from '../../components/layout/screen/screen';
import { BreadCrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { Header } from '../../components/layout/header/header';
import { DaytimeDegreesList } from '../../components/daytime-degrees/daytime-degrees-list';
import { AdsContainer } from '../ads/ads-container';

export const DaytimeDegreesContainer = () => {
    return (
        <Screen>
            <Title>Daggrader</Title>
            <BreadCrumbs prompt="Daggrader" />
            <Header header="Daggrader" backLink="/" />
            <DaytimeDegreesList />
            <AdsContainer placement={Placements.DaytimeDegrees} />
        </Screen>
    );
};
