import { createSignal, onMount, Show, useContext } from 'solid-js';
import { isServer } from 'solid-js/web';
import { createStore } from 'solid-js/store';
import { Title } from '@solidjs/meta';
import { Placements } from '@24hr/gullvikspraktikan-entities';
import { BreadCrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { Container } from '../../components/layout/container/container';
import { Header } from '../../components/layout/header/header';
import { Screen } from '../../components/layout/screen/screen';
import { AdsContainer } from '../ads/ads-container';
import { getUpmaProductsQuery } from '../../graphql/queries/products';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { UpmaControls } from '../../components/upma/upma-controls';
import { ListFilterArgs } from './upma-types';
import { StyledVerticalSpace } from '../../components/layout/layout.styles';
import { UpmaList } from '../../components/upma/upma-list';
import { getUpmaListQuery } from '../../graphql/queries/upma';
import { PaginationArgs } from '../../components/pagination/pagination-types';

const LIMIT = 15;

export const UpmaContainer = () => {
    const { createCachedResource, graphQlClient } = useContext(AppContext);
    const [isLoadingResults, setIsLoadingResults] = createSignal(false);
    const [upmas, setUpmas] = createSignal({
        upmaList: {
            rows: [],
        },
    });

    const [filter, setFilter] = createSignal<ListFilterArgs>({
        activeOnly: true,
        productSlugs: [],
        searchTerm: '',
        limit: LIMIT,
        offset: 0,
    });

    const [pagination, setPagination] = createStore<PaginationArgs>({
        perPage: LIMIT,
        currentPage: 1,
        totalRecords: 0,
    });

    const onPaginationChange = (currentPage: number) => {
        setPagination({ currentPage });

        let offset = 0;
        if (currentPage > 1) {
            offset = pagination.perPage * (currentPage - 1);
        }

        onFilterUpdate({ offset }, true, false);
    };

    const [products] = createCachedResource(getUpmaProductsQuery, {}, true);

    const onFilterUpdate = (args: Partial<ListFilterArgs>, scrollToTop = false, resetPagination = true) => {
        setFilter({
            ...filter(),
            ...args,
        });

        if (resetPagination) {
            setPagination({ currentPage: 1 });
        }

        updateUpmas();

        if (scrollToTop && window) {
            window.scrollTo({
                top: 0,
                behavior: 'auto',
            });
        }
    };

    const selectListProductsLoaded = () => (products()?.upmaProducts ? true : false);

    const updateUpmas = async () => {
        setIsLoadingResults(true);
        const response = await graphQlClient(getUpmaListQuery, filter());
        setIsLoadingResults(false);

        setPagination({ totalRecords: response?.upmaList?.count || 0 });

        return setUpmas(response);
    };

    onMount(() => updateUpmas());

    return (
        <Screen>
            <Title>UPMA - Gullvikspraktikan</Title>
            <BreadCrumbs prompt="UPMA" />
            <Header header="UPMA" backLink="/" />
            <Container>
                <Show when={selectListProductsLoaded()} fallback={<div>Laddar...</div>}>
                    <UpmaControls products={products().upmaProducts} currentFilters={filter()} onFilterUpdate={onFilterUpdate} />
                </Show>
                <StyledVerticalSpace size={2} />
                <Show when={isLoadingResults() && !isServer}>Laddar...</Show>
                <UpmaList
                    upmas={upmas}
                    pagination={{
                        ...pagination,
                        onChange: onPaginationChange,
                    }}
                />
                <AdsContainer placement={Placements.Upma} />
            </Container>
        </Screen>
    );
};
