import { useContext, Show, createSignal, createResource } from 'solid-js';
import { Title } from '@solidjs/meta';
import { Placements } from '@24hr/gullvikspraktikan-entities';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Screen } from '../../components/layout/screen/screen';
import { BreadCrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { Header } from '../../components/layout/header/header';
import { Container } from '../../components/layout/container/container';
import { MixingTableControls } from '../../components/mixing-table/mixing-table';
import { MixingTableList } from '../../components/mixing-table/mixing-table-list';
import { getMixableProductsQuery, getProductQuery } from '../../graphql/queries/products';
import { StyledVerticalSpace } from '../../components/layout/layout.styles';
import { AdsContainer } from '../ads/ads-container';
import { StyledDisclaimer } from './mixing-table.styles';

export const MixingTableContainer = () => {
    const { createCachedResource, graphQlClient } = useContext(AppContext);

    const [currentProduct, setCurrentProduct] = createSignal<string | null>(null);
    const [product2, setProduct2] = createSignal('');
    const [statusFilter, setStatusFilter] = createSignal<string[] | null>(null);

    const [products] = createCachedResource(getMixableProductsQuery, { limit: 500 }, true);
    const [productMixingData] = createResource(currentProduct, (slug) => graphQlClient(getProductQuery, { slug }));

    const loaded = () => (products()?.mixableProducts ? true : false);
    const mixingDataLoaded = () => (productMixingData()?.product ? true : false);

    return (
        <Screen>
            <>
                <Title>Blandningstabeller - Gullvikspraktikan</Title>
                <BreadCrumbs prompt="Blandningstabeller" />
                <Header header="Blandningstabeller" backLink="/" />
                <Container>
                    <Show when={loaded()} fallback={'Laddar...'}>
                        <MixingTableControls
                            products={products().mixableProducts}
                            updateCurrentProduct={(val: string) => setCurrentProduct(val)}
                            updateProduct2={(val: string) => setProduct2(val)}
                            currentProduct1hasMixings={productMixingData()?.product.mixings.length ? true : false}
                            currentProduct={currentProduct()}
                            product2={product2()}
                            statusFilter={statusFilter()}
                            updateStatusFilter={(val: string[]) => setStatusFilter(val)}
                        />
                        <StyledVerticalSpace />
                        <Show when={mixingDataLoaded()}>
                            <MixingTableList product={productMixingData()?.product} product2={product2()} statusFilter={statusFilter()} />
                        </Show>
                    </Show>
                    <AdsContainer placement={Placements.MixingTable} />
                    <StyledDisclaimer>
                        OBS! Blandningsrekommendationerna gäller inom en normalvariation på vatten och samlad erfarenhet. Beroende på
                        vattenegenskaper kan avvikande blandningsresultat förekomma. Provblanda före tankblandning i hink med gårdens vatten
                        om rekommendation saknas.
                    </StyledDisclaimer>
                </Container>
            </>
        </Screen>
    );
};
