import { useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { Contacts } from '../../components/contacts/contacts';
import { getContactAreasQuery } from '../../graphql/queries/contact-areas';
import { getContactsQuery } from '../../graphql/queries/contacts';

export const ContactsContainer = () => {
    const { createCachedResource } = useContext(AppContext);

    const [contactsData] = createCachedResource(getContactsQuery);
    const [contactAreasData] = createCachedResource(getContactAreasQuery);

    return <Contacts contactsData={contactsData} contactAreasData={contactAreasData} />;
};
