import { Container } from './layout/container/container';
import { Header } from './layout/header/header';
import { StyledVerticalSpace } from './layout/layout.styles';
import { Row } from './layout/row/row';
import { Screen } from './layout/screen/screen';
import { parseErrorMessage } from '../tools/parse-errors';
import Button from './button/button';
import { useNavigate } from '@solidjs/router';

type ErrorProps = {
    error?: any;
};

export const ErrorComponent = (props: ErrorProps) => {
    const navigate = useNavigate();
    const message = () => parseErrorMessage(props.error) || 'Tyvärr har applikationen råkat ut får ett fel :/';

    return (
        <Screen>
            <Header header="Ett fel har inträffat!" backLink="/" />
            <Container>
                <StyledVerticalSpace />
                <Row justifyContent="center">
                    <p>
                        <strong>{message()}</strong>
                    </p>
                </Row>
                <StyledVerticalSpace />
                <Row justifyContent="center">
                    <Button variant="secondary" label="Gå tillbaka" onClick={() => navigate('/')} />
                </Row>
            </Container>
        </Screen>
    );
};
