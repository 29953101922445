import { For, Show } from 'solid-js';
import { Placements } from '@24hr/gullvikspraktikan-entities';
import { Title } from '@solidjs/meta';
import { Header } from '../layout/header/header';
import { Container } from '../layout/container/container';
import { Link } from '../link/link';
import type { CropTypesProps } from './crop-types.d';
import { BreadCrumbs } from '../breadcrumbs/breadcrumbs';
import { AdsContainer } from '../../containers/ads/ads-container';
import { SkeletonLoader } from '../skeleton-loader/skeleton-loader';

export const CropTypes = (props: CropTypesProps) => {
    const category = () => props.cropTypeCategoryData()?.cropTypeCategory;
    const prompt = 'Välj grödgrupp';

    const links = () => [
        {
            text: category()?.name || '',
            url: `/strategier/${category()?.slug}/`,
        },
    ];

    return (
        <>
            <Title>{`${category()?.name || ''} - Gullvikspraktikan`}</Title>
            <BreadCrumbs links={links()} prompt={`${prompt}...`} />
            <Header header={category()?.name || ''} backLink="/" />
            <Container header="Välj grödgrupp">
                <Show when={category()?.cropTypes?.length} fallback={<SkeletonLoader />}>
                    <For each={category()?.cropTypes}>
                        {(cropType) => <Link href={`/strategier/${category()?.slug}/${cropType.slug}`}>{cropType.name}</Link>}
                    </For>
                </Show>
                <AdsContainer placement={Placements.CropTypeCategory} slug={category()?.slug} />
            </Container>
        </>
    );
};
